import Cookies from "js-cookie";

export function initModals() {
  let currentlyShowingPopup = false;
  let cookieClickedAwayExit = false;

  const exitModal = document.getElementById("exit-modal-popup");

  if (Cookies.get("exitPopup") === undefined) {
    document.body.addEventListener("mouseleave", () => {
      if (!currentlyShowingPopup && !cookieClickedAwayExit) {
        exitModal.showModal();
        currentlyShowingPopup = true;
      }
    });
  }

  document.querySelectorAll(".close-exit-popup").forEach((button) => {
    button.addEventListener("click", () => {
      exitModal.close();
      Cookies.set("exitPopup", "shown", {expires: 7});
      currentlyShowingPopup = false;
    });
  });

  document.querySelectorAll(".video-modal-button").forEach((button) => {
    button.addEventListener("click", () => {
      const modal = document.getElementById(button.dataset.videoValue);

      if (modal != null) {
        modal.showModal();

        const videoId = button.dataset.videoValue.split("-")[2];
        const video = document.getElementById(`video-${videoId}`);

        if (video != null) {
          video.play();
        }
      }
    });
  });
}
