import Splide from "@splidejs/splide";
import {AutoScroll} from "@splidejs/splide-extension-auto-scroll";

export function initializeSplide() {
  document.addEventListener("DOMContentLoaded", function () {
    var splideTestiElement = document.querySelector(".splide-testimonials");
    var splideReferenzenElement2 = document.querySelector(".splide.referenzen");
    var splideBilderElement = document.querySelector(".splide.bilder");
    //var splideLogosHeader = document.querySelector(".splide-logos-header")
    var splideLogosHeader = document.querySelectorAll(".splide-logos-header");
    var themenSplide = document.querySelectorAll(".projekt-themen-container");

    if (splideTestiElement) {
      var splideTesti = new Splide(splideTestiElement, {
        autoScroll: {
          speed: 0.5,
        },
        type: "loop",
        drag: "free",
        arrows: false,
        focus: "center",
        padding: "0",
        perPage: 3,
        perMove: 1,
        gap: "0",
        pagination: false,
        breakpoints: {
          1400: {
            perPage: 2,
          },
          768: {
            perPage: 1,
            gap: "1rem",
            padding: 0,
          },
        },
      });
      splideTesti.mount({AutoScroll});
    }

    if (splideLogosHeader.length > 0) {
      splideLogosHeader.forEach((element) => {
        var splideTesti = new Splide(element, {
          autoWidth: true,
          autoScroll: {
            speed: 0.5,
          },
          type: "loop",
          drag: "free",
          arrows: false,
          focus: "start",
          perPage: 4,
          perMove: 1,
          gap: "2rem",
          pagination: false,
          breakpoints: {
            1024: {
              perPage: 2,
              gap: "1rem",
            },
          },
        });
        splideTesti.mount({AutoScroll});
      });
    }

    if (splideReferenzenElement2) {
      var splideReferenzen = new Splide(splideReferenzenElement2, {
        type: "loop",
        // autoplay: true,
        drag: true,
        perPage: 1,
        perMove: 1,
        arrows: false,
        gap: "1rem",
        pagination: false,
      });
      splideReferenzen.mount();

      var forwardButton = document.querySelector(".splide-left");
      var backwardButton = document.querySelector(".splide-right");

      forwardButton.addEventListener("click", function () {
        splideReferenzen.go("<");
      });

      backwardButton.addEventListener("click", function () {
        splideReferenzen.go(">");
      });
    }

    if (splideBilderElement) {
      var splideBilder = new Splide(".splide.bilder", {
        type: "loop",
        drag: false,
        perPage: 1,
        perMove: 1,
        arrows: false,
        gap: "2rem",
        pagination: false,
      });

      splideBilder.mount();
    }

    if (themenSplide.length > 0) {
      themenSplide.forEach((element) => {
        var splideThemen = element.querySelector(".projekt-themen");
        var splideThemenMount = new Splide(splideThemen, {
          type: "loop",
          drag: true,
          perPage: 4,
          perMove: 1,
          start: 0,
          arrows: false,
          gap: "0rem",
          pagination: false,
          breakpoints: {
            1024: {
              perPage: 2,
              gap: "0rem",
            },
            768: {
              perPage: 1,
              gap: "0rem",
            },
          },
        });
        splideThemenMount.mount();
        var forwardButton = element.querySelector(".increase-one");
        forwardButton.addEventListener("click", function () {
          splideThemenMount.go(">");
        });
      });
    }
  });
}
