export function initAccordion() {
  const accordionItems = document.querySelectorAll(".accordion-collapse");

  accordionItems.forEach((item) => {
    const headline = item.querySelector(".accordion-item-headline");

    headline.addEventListener("click", () => {
      const isOpen = item.classList.contains("is-open");

      if (isOpen) {
        item.classList.remove("is-open");
      } else {
        item.classList.add("is-open");
      }
    });
  });
}
