import AOS from "aos";
import "aos/dist/aos.css";
import {initAccordion} from "./scripts/accordion.js";
import {initAnimations} from "./scripts/animations.js";
import {initLazyLoad} from "./scripts/lazyload.js";
import {initModals} from "./scripts/modals.js";
import {playVideo} from "./scripts/playvideo.js";
import {initializeSplide} from "./scripts/splide.js";
import "./styles/main.scss";
import {handleResize} from "./scripts/resize.js";

initializeSplide();

document.addEventListener("DOMContentLoaded", () => {
  initLazyLoad(); // Initialize Lazy Loading
  initModals(); // Initialize Modals and Popups
  initAnimations();
  initAccordion();
  playVideo();

  // Add smooth scroll behavior for anchor links
  document.querySelectorAll('a[href^="#"], a[href^="/#"]').forEach((anchor) => {
    anchor.addEventListener("click", function (e) {
      e.preventDefault();
      let targetId = this.getAttribute("href");

      if (targetId.startsWith("/") && window.location.pathname !== "/") {
        window.location.href = targetId;
        return;
      }

      targetId = targetId.replace(/^\/?/, "");

      if (targetId === "#") return;

      const targetElement = document.querySelector(targetId);

      if (targetElement != null) {
        document.getElementById("mobileMenu").style.right = "-100%";

        targetElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    });
  });

  document.querySelectorAll(".quote-expandable").forEach((quote) => {
    const expandBtn = quote.querySelector(".expand-button");
    const collapseBtn = quote.querySelector(".collapse-button");
    const shortText = quote.querySelector(".quote-short");
    const fullText = quote.querySelector(".quote-full");

    expandBtn?.addEventListener("click", () => {
      shortText.classList.add("hidden");
      fullText.classList.remove("hidden");
    });

    collapseBtn?.addEventListener("click", () => {
      fullText.classList.add("hidden");
      shortText.classList.remove("hidden");
    });
  });
});

window.addEventListener("load", () => {
  AOS.refresh();
  setTimeout(() => {
    AOS.refresh();
  }, 1000);
});

window.addEventListener("resize", handleResize);
