export function playVideo() {
  const playButtons = document.querySelectorAll(".video-play-button");

  playButtons.forEach((button) => {
    button.addEventListener("click", function () {
      const videoValue = button.getAttribute("video-value");
      const videoSection = document.getElementById(videoValue);
      const imageCover = videoSection.querySelector(".full-video-image-cover");
      const video = videoSection.querySelector(".full-video");

      console.log("videoSection", videoSection);

      imageCover.style.display = "none";
      button.style.display = "none";
      video.classList.remove("hidden");

      video.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });

      video.play();
    });
  });
}
